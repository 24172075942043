import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import OptionsGroup from '../../OptionsGroup';

import { categoriesColour } from '../../../../data/optionCategories';

const Colour = ({ channel, setTwoColumns }) => {
  return <OptionsGroup categories={categoriesColour} channel={channel} setTwoColumns={setTwoColumns} />;
};

Colour.propTypes = {
  channel: PropTypes.string.isRequired,
  setTwoColumns: PropTypes.func.isRequired,
};

export default Colour;
