import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, Provider, useDispatch } from 'react-redux';

// Redux store
import store from '../../store/store';

// Helpers
import { contains, unique } from '../../utils';
import { isInCategory, isVisible, isEnabled, filterCategories } from './helpers';

// Component Imports
import Option from './Option';
import NoOptions from './NoOptions';

// Style Imports
import './OptionsGroup.scss';

const DefaultOptions = ({ defaultOptions }) => {
  if (defaultOptions.length === 0) return null;

  const descriptions = defaultOptions.map((option) => (
    <span className="b-label default-options__option" key={option.description}>
      {option.description}
    </span>
  ));

  let comeLabel = 'all come';
  if (defaultOptions.length === 1) {
    comeLabel = 'comes';
  } else if (defaultOptions.length === 2) {
    comeLabel = 'both come';
  }

  return (
    <p className="default-options">
      {descriptions} {comeLabel} as standard.
    </p>
  );
};

DefaultOptions.propTypes = {
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({ description: PropTypes.string })).isRequired,
};

const OptionsGroup = ({ categories, setTwoColumns }) => {
  // React Redux hooks
  const deal = useSelector((state) => state.Deal);
  const dispatch = useDispatch();

  const filteredCategories = [];

  filterCategories(Object.entries(deal.categories), categories).forEach(([name, code]) => {
    const data = deal.options.filter(isInCategory(code));
    const visibleArray = data.map((option) => isVisible(option.id, deal));

    if (visibleArray.includes(true)) {
      filteredCategories.push({ name, code, data });
    }
  });

  useEffect(() => {
    setTwoColumns(filteredCategories.length > 2);
  }, [filteredCategories]);

  const getPackDescriptions = (option) => {
    return unique(option.packContents).sort();
  };

  if (filteredCategories.length === 0) return <NoOptions />;

  return filteredCategories.map(({ name, code, data }) => {
    const defaultOptions = data.filter((option) => option.isDefault);
    const selectableOptions = data.filter((option) => !option.isDefault);
    const visibleSelectableOptions = selectableOptions.filter((option) => isVisible(option.id, deal));

    if (visibleSelectableOptions.length === 0) return null;

    return (
      <div className="options-selector-group" role="group" key={code}>
        <h3 className="options-selector-group__heading">{name}</h3>

        <DefaultOptions defaultOptions={defaultOptions} />

        {visibleSelectableOptions.map((option) => {
          const checked = contains(option.id, deal.selected.optionIds);

          return (
            <Option
              id={option.id}
              onChange={() =>
                dispatch({
                  type: checked ? 'OPTIONS.REMOVE' : 'OPTIONS.ADD',
                  id: option.id,
                })
              }
              isDefault={option.isDefault}
              checked={checked}
              visible={isVisible(option.id, deal)}
              disabled={deal.isStock || deal.isFrozen || !isEnabled(option.id, deal) || (option.isDefault && checked)}
              description={option.description}
              price={option.price}
              contractLength={deal.contractLength}
              initialMonths={deal.initialMonths}
              packDescriptions={getPackDescriptions(option)}
              isAdmin={deal.isAdmin}
              key={option.id}
            />
          );
        })}
      </div>
    );
  });
};

OptionsGroup.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.number).isRequired,
  setTwoColumns: PropTypes.func.isRequired,
};

const OptionsGroupWrapper = ({ categories, channel, setTwoColumns }) => (
  <Provider store={store}>
    <OptionsGroup categories={categories} channel={channel} setTwoColumns={setTwoColumns} />
  </Provider>
);

OptionsGroupWrapper.propTypes = OptionsGroup.propTypes;

export default OptionsGroupWrapper;
