import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Style imports
import './OptionSelectorPagination.scss';

const OptionSelectorPagination = ({ name, pages, jumpToStep, loggedIn }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.Deal);

  const handleClick = (event, index) => {
    event.preventDefault();

    dispatch({ type: 'SET_DEAL_CURRENT_STEP', payload: index + 1 });

    jumpToStep(index);
  };

  const handleViewChange = (event, type) => {
    event.preventDefault();
    const isSales = !!(type === 'sales');

    if (isSales) {
      jumpToStep(0);
      dispatch({ type: 'SET_DEAL_CURRENT_STEP', payload: 1 });
    }

    dispatch({ type: 'SET_DEAL_SALES_VIEW', payload: isSales });
  };

  return (
    <nav
      className={classNames('option-selector-pagination', { 'option-selector-pagination--logged-in': loggedIn })}
      aria-label="Deal options navigation"
    >
      {loggedIn && (
        <div className="option-selector-pagination__view-control" role="group">
          <button
            className={classNames('option-selector-pagination__button', 'option-selector-pagination__button--sales', {
              'option-selector-pagination__button--active': query.salesView,
            })}
            onClick={(event) => handleViewChange(event, 'sales')}
          >
            <span className="sr-only">Sales view</span>
          </button>

          <button
            className={classNames(
              'option-selector-pagination__button',
              'option-selector-pagination__button--customer',
              { 'option-selector-pagination__button--active': !query.salesView },
            )}
            onClick={(event) => handleViewChange(event, 'customer')}
          >
            <span className="sr-only">Customer view</span>
          </button>
        </div>
      )}

      <h2 className="option-selector-pagination__title">Select your {name.toLowerCase()}</h2>

      <div className="option-selector-pagination__progress">
        {!!pages &&
          pages.map((item, index) => {
            const count = index + 1;

            const listItemClasses = classNames({
              'option-selector-pagination__item': true,
              'option-selector-pagination__item--sales-view': loggedIn && query.salesView,
              'option-selector-pagination__item--current': query.currentStep === count,
            });

            return (
              <a key={item.name} href="/" className={listItemClasses} onClick={(event) => handleClick(event, index)}>
                <span className="option-selector-pagination__label">{item.name}</span>
              </a>
            );
          })}
      </div>
    </nav>
  );
};

OptionSelectorPagination.propTypes = {
  name: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  jumpToStep: PropTypes.func.isRequired,
};

export default OptionSelectorPagination;
