import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Helpers
import {
  getSelectedOptions,
  isPaintwork,
  isAllTrim,
  isPacks,
  isExterior,
  isInterior,
  isEquipment,
  numberWithCommas,
} from '../helpers';

// Style Imports
import './OptionSelectorSummary.scss';

const OptionSelectorSummaryItem = ({ name, selected }) => {
  return (
    <li className="option-selector-summary__item">
      <p className="option-selector-summary__option-name">{name}:</p>

      <ul className="option-selector-summary__option-values">
        {selected.length > 0 &&
          selected.map((item) => (
            <li className="option-selector-summary__option-value" key={item.description}>
              {item.description}
            </li>
          ))}
      </ul>
    </li>
  );
};

OptionSelectorSummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const isPositiveNumber = (num) => typeof num === 'number' && num > 0;

const OptionSelectorSummary = ({ isPersonal, jumpToStep }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const deal = useSelector((state) => state.Deal);

  const handleClear = (event) => {
    event.preventDefault();
    dispatch({ type: 'CLEAR_SELECTED_OPTIONS' });
    jumpToStep(0);
  };

  const selectedColour = getSelectedOptions(deal).filter(isPaintwork);
  const selectedTrim = getSelectedOptions(deal).filter(isAllTrim);
  const selectedPacks = getSelectedOptions(deal).filter(isPacks);
  const selectedExterior = getSelectedOptions(deal).filter(isExterior);
  const selectedInterior = getSelectedOptions(deal).filter(isInterior);
  const selectedEquipment = getSelectedOptions(deal).filter(isEquipment);

  const {
    monthlyPrice,
    maintainedMonthlyPrice,
    maintenanceSelected,
    initialPaymentPrice,
    maintainedInitialPaymentPrice,
  } = deal;

  const price = maintenanceSelected ? maintainedMonthlyPrice : monthlyPrice;
  const initialPayment = maintenanceSelected ? maintainedInitialPaymentPrice : initialPaymentPrice;

  let priceParts;

  if (isPositiveNumber(price)) {
    priceParts = price.toFixed(2).toString().split('.');
  }

  return (
    <div className="option-selector-summary">
      <div className="option-selector-summary__total">
        <p className="option-selector-summary__period">Per month</p>
        <p className="option-selector-summary__price">
          {isPositiveNumber(price) ? (
            <>
              £{priceParts[0]}
              <span className="option-selector-summary__price-pence">.{priceParts[1]}</span>
            </>
          ) : (
            'Call us'
          )}
        </p>
      </div>

      {!!deal.lx_monthly_increase && <div className="alert alert-warning">Luxury Car Tax has been applied.</div>}

      <ul className="option-selector-summary__list">
        {isPositiveNumber(deal.annualMileage) && (
          <li className="option-selector-summary__item">
            <p className="option-selector-summary__item-name">Annual Mileage:</p>
            <p className="option-selector-summary__item-value">{numberWithCommas(deal.annualMileage)} miles</p>
          </li>
        )}

        {isPositiveNumber(initialPayment) && (
          <li className="option-selector-summary__item">
            <p className="option-selector-summary__item-name">Initial Payment:</p>
            <p className="option-selector-summary__item-value">
              £{numberWithCommas(initialPayment.toFixed(2))} ({isPersonal ? 'inc' : 'ex'}. VAT)
            </p>
          </li>
        )}

        <li className="option-selector-summary__item">
          <p className="option-selector-summary__item-name">Contract Length:</p>
          <p className="option-selector-summary__item-value">{deal.contractLength} months</p>
        </li>
      </ul>

      <h4 className="option-selector-summary__heading">Your options</h4>

      <ul className="option-selector-summary__list option-selector-summary__list--options">
        <OptionSelectorSummaryItem name="Colour" selected={selectedColour} />

        <OptionSelectorSummaryItem name="Trim" selected={selectedTrim} />

        <OptionSelectorSummaryItem name="Packs" selected={selectedPacks} />

        <OptionSelectorSummaryItem name="Exterior" selected={selectedExterior} />

        <OptionSelectorSummaryItem name="Interior" selected={selectedInterior} />

        <OptionSelectorSummaryItem name="Equipment" selected={selectedEquipment} />
      </ul>

      <button className="option-selector-summary__clear" onClick={handleClear}>
        <span className="option-selector-summary__clear-icon" />
        Clear and start again
      </button>
    </div>
  );
};

OptionSelectorSummary.propTypes = {
  isPersonal: PropTypes.bool.isRequired,
  jumpToStep: PropTypes.func.isRequired,
};

export default OptionSelectorSummary;
