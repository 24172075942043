import React from 'react';
import { useSelector, Provider } from 'react-redux';
import PropTypes from 'prop-types';

import store from '../../../store/store';

// Style imports
import './OptionSelectorStockWarning.scss';

const EnquireNow = ({ enquirePath }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="option-selector-stock-warning__enquire-now"
      data-action="modal#close modal#load"
      data-target="enquire-modal"
      data-path={enquirePath}
      href="#"
      role="button"
    >
      Please enquire
    </a>
  );
};

EnquireNow.propTypes = {
  enquirePath: PropTypes.string.isRequired,
};

const OptionSelectorStockWarning = ({ enquirePath, channel }) => {
  const deal = useSelector((state) => state.Deal);

  if (!deal.isStock && !deal.isFrozen && !deal.isShortTerm) return null;

  let warning_text = 'This vehicle is part of a limited stock deal and therefore options cannot be added.';
  if (deal.isShortTerm) {
    warning_text =
      'Short term lease vehicle specifications cannot be customised as vehicles are either already built or on route from the factory.';
  } else if (deal.isFrozen && !deal.isStock) {
    warning_text = 'Additional options can not be selected at this time.';
  }

  return (
    <div className="option-selector-stock-warning alert alert-info text-center">
      <p>
        <span className="option-selector-stock-warning__emphasis">Please note:</span> {warning_text}
      </p>
      {!deal.isShortTerm && (
        <p>
          <EnquireNow enquirePath={enquirePath} channel={channel} /> for specific requirements including choice of
          colours.
        </p>
      )}
    </div>
  );
};

OptionSelectorStockWarning.propTypes = {
  enquirePath: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
};

export default (props) => (
  <Provider store={store}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <OptionSelectorStockWarning {...props} />
  </Provider>
);
