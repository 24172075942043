/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Style Imports
import './MonthlyPrice.scss';

const MonthlyPrice = ({ price, isBusiness, isLoading, isEnquire }) => {
  if (price) {
    const [pounds, pence] = price.toFixed(2).toString().split('.');

    return (
      <div className="monthly-price">
        {isLoading ? (
          <span className="monthly-price__loading" />
        ) : (
          <p className={isBusiness ? 'business' : null}>
            {isEnquire ? (
              <>
                <NumberFormat value={pounds} displayType="text" thousandSeparator prefix="£" fixedDecimalScale />
                <span className="g-deal-enquire__pence">.{pence}</span>
                <span className="g-deal-enquire__mobile"> p/m</span>
              </>
            ) : (
              <>
                <NumberFormat value={pounds} displayType="text" thousandSeparator prefix="£" fixedDecimalScale />
                <span className="g-deal-options__pence">.{pence}</span>
              </>
            )}
          </p>
        )}
      </div>
    );
  }

  return <p>Call us</p>;
};

MonthlyPrice.propTypes = {
  price: PropTypes.number,
  isBusiness: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEnquire: PropTypes.bool,
};

export default MonthlyPrice;
