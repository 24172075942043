import React from 'react';
import PropTypes from 'prop-types';
import SalesInfoItem from './SalesInfoItem';

const Dealer = ({ name, contact, commission, link, leadTime, stockList }) => {
  const formatLeadTime = () => {
    // eslint-disable-next-line no-useless-escape
    return leadTime.replace(/<br\s*[\/]?>/gi, '\n');
  };

  return (
    <div className="g-deal-sales-info__group">
      {link && (
        <div className="g-deal-sales-info__item">
          <p>
            <a href={link}>{name}</a>
          </p>
        </div>
      )}

      <SalesInfoItem title="Lead time" content={formatLeadTime()} />
      <SalesInfoItem title="Commission" content={commission} />
      <SalesInfoItem title="Contact" content={contact} />

      {stockList && (
        <div className="g-deal-sales-info__item">
          <p>
            <a href={stockList}>View Stock List</a>
          </p>
        </div>
      )}
    </div>
  );
};

Dealer.propTypes = {
  name: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  commission: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  leadTime: PropTypes.string.isRequired,
  stockList: PropTypes.string,
};

Dealer.defaultProps = {
  stockList: '',
};

export default Dealer;
