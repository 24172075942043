import React from 'react';
import PropTypes from 'prop-types';
import { Provider, useSelector } from 'react-redux';

// Redux store
import store from '../../store/store';

// Helpers
import { getSelectedOptions } from './helpers';
import { formatPrice } from '../../utils';

const CarTax = ({ taxConfig, p11d, vat }) => {
  const deal = useSelector((state) => state.Deal);
  const options = getSelectedOptions(deal);
  const totalOptionPrice =
    options.length > 0 ? options.map((option) => option.price).reduce((total, num) => total + num) : null;

  const bik_value = (benefit, p11dParam, percentage) => {
    if (!benefit || !p11dParam) {
      return 'N/A';
    }

    return formatPrice((p11dParam + totalOptionPrice * vat) * (benefit / 100) * (percentage / 100));
  };

  return (
    <>
      <h2 className="drv-text-title drv-mb-4">Company car tax calculator</h2>
      <table className="drv-table drv-mt-4">
        <thead>
          <tr>
            <th>Tax Year</th>
            <th>BIK</th>
            <th>BIK Value</th>
            <th>Tax Payable at 20%</th>
            <th>Tax Payable at 40%</th>
          </tr>
        </thead>
        <tbody>
          {taxConfig.map(({ year, benefit }) => (
            <tr key={year}>
              <td>{year}</td>
              <td>{benefit ? `${benefit}%` : 'N/A'}</td>
              <td>{bik_value(benefit, p11d, 100)}</td>
              <td>{bik_value(benefit, p11d, 20)}</td>
              <td>{bik_value(benefit, p11d, 40)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

CarTax.propTypes = {
  taxConfig: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      benefit: PropTypes.number,
    }),
  ).isRequired,
  p11d: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
};

export default function CarTaxWrapper(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CarTax {...props} />
    </Provider>
  );
}
