import { curry, intersection, all, any, unique, contains } from '../../utils';

import {
  categoriesColour,
  categoriesTrim,
  categoriesPacks,
  categoriesExterior,
  categoriesInterior,
  categoriesEquipment,
} from '../../data/optionCategories';

// CONSTANTS
export const ONE_OF = 'OO';
export const REQUIRES_ONE = 'RO';
export const REQUIRES_ALL = 'RA';
export const NOT_WITH = 'NW';
export const INCLUDED_IN = 'IN';
export const INCLUDE_ONE = 'IO';

// GETTERS

//           getOption :: (String, State) -> Option?
export const getOption = (optionId, state) => state.options.find(({ id }) => `${id}` === `${optionId}`);

export const getSelectedOptions = (state) => state.options.filter(({ id }) => contains(id, state.selected.optionIds));

//           getRule :: (String, State) -> Rule?
export const getRule = (ruleId, state) => state.rules.find(({ id }) => id === ruleId);

//           getRules ::([String, state]) -> [Rule?]
export const getRules = (ruleIds, state) => state.rules.filter(({ id }) => contains(id, ruleIds));

//           getRulesOfTypeForOption :: (String, String, State) -> [Rule?]
export const getRulesOfTypeForOption = (optionId, ruleType, state) =>
  getOption(optionId, state).rules.filter(({ type }) => type === ruleType);

//    getRulesForOptions :: ([String], State) => [Rule?]
export const getRulesForOptions = (optionIds, state) =>
  unique(
    state.options
      .filter(({ id }) => contains(id, optionIds))
      .map(({ ruleIds }) => ruleIds)
      .flat(),
  );

//    getOneOfRulesForOption :: (String, State) -> [Rule?]
export const getOneOfRulesForOption = (optionId, state) => getRulesOfTypeForOption(optionId, 'OO', state);

// FILTERS

//           isChildPackOption :: (id, state) -> Boolean
export const isChildPackOption = (optionId, state) => {
  const option = getOption(optionId, state);
  const includedInRulesPrimaryOptions = option.rules
    .filter(({ type, primaryOptionId }) => type === INCLUDED_IN && primaryOptionId !== optionId)
    .map(({ primaryOptionId }) => primaryOptionId);
  const requiresRulesOptions = unique(
    option.rules
      .filter(
        ({ type, primaryOptionId }) => contains(type, [REQUIRES_ONE, REQUIRES_ALL]) && primaryOptionId === optionId,
      )
      .map(({ optionIds }) => optionIds)
      .flat(),
  );

  return intersection(includedInRulesPrimaryOptions, requiresRulesOptions).length > 0;
};

export const isVisible = (optionId, state) => {
  const selectedChildPackOption = isChildPackOption(optionId, state) && contains(optionId, state.selected.optionIds);

  return !isChildPackOption(optionId, state) || selectedChildPackOption;
};

//           isEnabled :: (id, state) -> Boolean
export const isEnabled = (optionId, state) => {
  const option = getOption(optionId, state);

  if (contains(optionId, state.selected.optionIds)) {
    const selectedIncludedInRules = getRules(intersection(option.ruleIds, state.selected.ruleIds), state).filter(
      ({ type }) => type === INCLUDED_IN,
    );

    if (selectedIncludedInRules.length > 0) {
      return all(
        selectedIncludedInRules.map(({ primaryOptionId }) => {
          if (primaryOptionId !== optionId && contains(primaryOptionId, state.selected.optionIds)) {
            return false;
          }
          return true;
        }),
      );
    }

    return true;
  }

  // // "REQUIRES ONE" is the only option that requires us to check the selected options regardless of
  // // if the rule is applied or not
  const primaryRequiresOneRulesForOption = option.rules.filter(
    // TODO: this should also check for REQUIRES_ALL
    ({ primaryOptionId, type }) => primaryOptionId === optionId && type === REQUIRES_ONE,
  );

  const enabledByRequiresOne = primaryRequiresOneRulesForOption.map(
    // if any of the other optionIds for the rule are selected, this is enabled.
    (rule) => state.selected.optionIds.some((id) => contains(id, rule.optionIds)),
  );

  if (primaryRequiresOneRulesForOption.length > 0 && !all(enabledByRequiresOne)) {
    return false;
  }

  const appliedRulesForOption = getRules(intersection(option.ruleIds, state.selected.ruleIds), state);

  if (appliedRulesForOption.length === 0) {
    return true;
  }

  const enabledByAppliedRules = appliedRulesForOption.map((rule) => {
    if (rule.type === ONE_OF) {
      return !any(rule.optionIds.map((id) => isChildPackOption(id, state)));
    }

    if (rule.type === NOT_WITH && rule.primaryOptionId) {
      const primaryOption = getOption(rule.primaryOptionId, state);
      return primaryOption.isDefault || !contains(rule.primaryOptionId, state.selected.optionIds);
    }

    return true;
  });

  return all(enabledByAppliedRules);
};

//           isOther :: Option -> Boolean
//           This is terribly named, but Other here means not paint or trim
export const isOther = ({ categoryCode }) => !contains(categoryCode, [...categoriesColour, ...categoriesTrim]);

//           isPaintwork :: Option -> Boolean
export const isPaintwork = ({ categoryCode }) => contains(categoryCode, categoriesColour);

//           is :: Option -> Boolean
export const isTrim = ({ categoryCode }) => contains(categoryCode, categoriesTrim);

//           isTrim :: Option -> Boolean
export const isAllTrim = ({ categoryCode }) => contains(categoryCode, categoriesTrim);

//           isPacks :: Option -> Boolean
export const isPacks = ({ categoryCode }) => contains(categoryCode, categoriesPacks);

//           isExterior :: Option -> Boolean
export const isExterior = ({ categoryCode }) => contains(categoryCode, categoriesExterior);

//           isInterior :: Option -> Boolean
export const isInterior = ({ categoryCode }) => contains(categoryCode, categoriesInterior);

//           isEquipment :: Option -> Boolean
export const isEquipment = ({ categoryCode }) => contains(categoryCode, categoriesEquipment);

//           isInCategory :: Integer -> Option -> Boolean
export const isInCategory = curry((code, { categoryCode }) => categoryCode === code);

// Returns array of category objects matching category code
export const filterCategories = (data, categoryCodes) => {
  const filtered = [];

  data.forEach((item) => {
    if (contains(item[1], categoryCodes)) {
      filtered.push(item);
    }
  });

  return filtered;
};

//           numberWithCommas :: Number -> String
export const numberWithCommas = (n) => {
  if (typeof n !== 'number' && typeof n !== 'string') return '';
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const selectedOfferId = ({ offerId, maintainedOfferId, maintenanceSelected }) => {
  return maintenanceSelected ? maintainedOfferId : offerId;
};

export const geotargetlyInit = (g, e, o, t, a, r, ge, tl, y) => {
  const s = function () {
    const def = `geotargetlygeocontent${geotargetly}_default`;
    const len = g.getElementsByClassName(def).length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        g.getElementsByClassName(def)[i].style.display = 'inline';
      }
    }
  };
  t = g.getElementsByTagName(e)[0];
  y = g.createElement(e);
  y.async = true;
  y.src = `https://g1584674684.co/gc?refurl=${g.referrer}&id=-MQlZ7_Rghz2O0OylT8S&winurl=${encodeURIComponent(window.location)}`;
  t.parentNode.insertBefore(y, t);
  y.onerror = function () {
    s();
  };
};

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}K`
    : Math.sign(num) * Math.abs(num);
};
