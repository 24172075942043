import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SalesInfoItem = ({ title, content, alert }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="g-deal-sales-info__item">
      <h4>{title}</h4>
      <p className={classNames({ 'g-deal-sales-info__alert': alert })}>{content}</p>
    </div>
  );
};

SalesInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  alert: PropTypes.bool,
};

SalesInfoItem.defaultProps = {
  content: '',
  alert: false,
};

export default SalesInfoItem;
