import React from 'react';
import PropTypes from 'prop-types';

import './NoOptions.scss';

const NoOptions = ({ message }) => <p className="no-options">{message}</p>;

NoOptions.propTypes = {
  message: PropTypes.string,
};

NoOptions.defaultProps = {
  message: 'Sorry, there are no options available.',
};

export default NoOptions;
