import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { numberWithCommas } from './helpers';

const Option = ({
  id,
  onChange,
  checked,
  disabled,
  visible,
  description,
  price,
  initialMonths,
  contractLength,
  packDescriptions,
  isAdmin,
}) => {
  if (!visible) return null;

  const formatPriceForPeriod = (value, period = 'p/m') => `+ £${numberWithCommas(value.toFixed(2))} ${period}`;

  const monthlyPrice = price / (initialMonths + contractLength - 1);
  const displayPrice = monthlyPrice === 0 ? 'No cost' : formatPriceForPeriod(monthlyPrice);

  return (
    <div className="options-selector-group__item">
      <input
        className="options-selector-group__input"
        id={`input_${id}`}
        type="checkbox"
        name="options"
        onChange={onChange}
        value={id}
        checked={checked}
        disabled={disabled}
      />

      <label className="options-selector-group__label" htmlFor={`input_${id}`}>
        <span className="options-selector-group__info">
          <span className="options-selector-group__description">{description}</span>
          <span className="options-selector-group__price">
            {disabled && checked ? 'Included in pack' : displayPrice}
            {isAdmin && monthlyPrice !== 0 && (
              <span className="options-selector-group__total-price">{formatPriceForPeriod(price, 'total')}</span>
            )}
          </span>
        </span>
        {packDescriptions.length > 0 && (
          <ul className="options-selector-group__contents c-list-ticks-featured">
            {packDescriptions.map((packDescription) => (
              <li key={packDescription} className="c-list-ticks-featured__item">
                {packDescription}
              </li>
            ))}
          </ul>
        )}
      </label>
    </div>
  );
};

Option.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  initialMonths: PropTypes.number.isRequired,
  contractLength: PropTypes.number.isRequired,
  packDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Option;
