import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Rails from '@rails/ujs';
import PropTypes from 'prop-types';
import { unique, currentChannelPath } from '../../utils';

import store from '../../store/store';

import MotoringTerms from './MotoringTerms';
import FinanceLease from './FinanceLease';

const DEFAULT_TERM = 24;
const DEFAULT_INITIAL_MONTHS = 9;

//    toQueryString :: Obj -> String
const toQueryString = (params) => {
  const esc = encodeURIComponent;

  return Object.keys(params)
    .map((key) => `${esc(key)}=${esc(params[key])}`)
    .join('&');
};

//    int :: String -> Int
const int = (string) => parseInt(string, 10);

const Pricing = ({
  mileages,
  shortTermMileages,
  paths,
  terms,
  shortTerms,
  initialPayments,
  shortTermInitialPaymentsByTerm,
  defaultInitialPaymentMonthsByTerm,
  init,
  isVan,
  isUsed,
  channel,
  hasShortTerm,
  hasStandardTerm,
}) => {
  const dispatch = useDispatch();
  const deal = useSelector((state) => state.Deal);

  const resetReduxState = () => {
    dispatch({ type: 'RESET_DEAL_STATE' });
  };

  useEffect(() => {
    document.addEventListener('turbolinks:load', resetReduxState());

    dispatch({
      type: 'INITIALISE',
      payload: {
        contractLength: int(init.contractLength || DEFAULT_TERM),
        annualMileage: int(init.annualMileage),
        initialMonths: int(init.initialMonths || DEFAULT_INITIAL_MONTHS),
        monthlyPrice: init.monthlyPrice,
        maintainedMonthlyPrice: init.maintainedMonthlyPrice,
        initialPaymentPrice: init.initialPaymentPrice,
        maintainedInitialPaymentPrice: init.maintainedInitialPaymentPrice,
        isFinanceLease: init.useFinanceLease,
        dealId: init.dealId,
        isShortTerm: init.isShortTerm,
        make: init.make,
        model: init.model,
        trim: init.trim,
        isVan,
        fueltype: init.fuelType,
        transmission: init.transmission,
        price: init.price,
      },
    });

    return () => {
      document.removeEventListener('turbolinks:load', resetReduxState());
    };
  }, []);

  const {
    annualMileage,
    contractLength,
    initialMonths,
    initialised,
    monthlyPrice,
    maintainedMonthlyPrice,
    maintenanceSelected,
    initialPaymentPrice,
    maintainedInitialPaymentPrice,
    isFinanceLease,
  } = deal;

  const maintenancePrice = maintainedMonthlyPrice && Math.max(0, maintainedMonthlyPrice - monthlyPrice);

  const price = maintenanceSelected ? maintainedMonthlyPrice : monthlyPrice;
  const initialPayment = maintenanceSelected ? maintainedInitialPaymentPrice : initialPaymentPrice;

  const initialiseOptions = () => {
    const loadOptionsForSelectedPricingType =
      // eslint-disable-next-line eqeqeq
      (typeof deal.optionsIsPersonal === 'undefined' || deal.optionsIsPersonal != deal.isPersonal) &&
      typeof deal.derivativeId !== 'undefined';

    if (loadOptionsForSelectedPricingType) {
      const url = `/${channel}/deals/${deal.dealId}/options.json${window.location.search}`;
      Rails.ajax({
        url,
        type: 'GET',
        dataType: 'json',
        accept: 'application/json',
        success: (result) => {
          dispatch({ type: 'OPTIONS.INIT', ...result });

          // If any 'actuallySelectedOptionIds' are present
          // For each id dispatch an OPTION.ADD event as though the user clicked an option
          deal.selected.actuallySelectedOptionIds.forEach((option) => {
            dispatch({ type: 'OPTIONS.ADD', id: option });
          });
        },
        error: () => {},
      });
      return <p>Loading...</p>;
    }
  };

  const fetchPrice = () => {
    // this bit is really important. We need to wait until redux has been initialised with the right data before continuing.
    // when switching to another deal the previous deal might still be in state, and if it is (i.e. this deal and that deal aren't the same) then don't continue until the new deal has finished initialising.
    if (
      !initialised ||
      (initialised && typeof deal.derivativeId !== 'undefined' && deal.derivativeId !== init.derivativeId)
    )
      return;

    if (isFinanceLease) {
      dispatch({ type: 'STORE_MONTHLY_PRICE', payload: init.financeLeaseMonthlyPrice });
      dispatch({ type: 'STORE_INITIAL_PAYMENT', payload: init.financeLeaseInitialPaymentPrice });
      return;
    }

    const data = {};

    if (annualMileage) {
      data.mileage = annualMileage;
    }

    if (contractLength) {
      data.term = contractLength;
    }

    if (initialMonths) {
      data.initial_payment = initialMonths;
    }

    if (init.modelYear) {
      data.model_year = init.modelYear;
    }

    const params = new URLSearchParams(window.location.search);

    // On load check URL for option_ids[]
    if (params.get('option_ids[]')) {
      // If exists get all unique options into an array
      const optionIDArray = unique(params.getAll('option_ids[]'));

      // Only run this once - deal.URLParamsInit flag set to true after first run
      if (!deal.URLParamsInit && deal.isTurbolinksLoad) {
        // Set selected options in redux equal to url params
        dispatch({
          type: 'DEAL_URL_PARAMS_INIT',
          payload: optionIDArray,
        });
      }
    }

    let url = `${window.location.pathname}.json?${toQueryString(data)}`;

    if (deal.selected.actuallySelectedOptionIds) {
      deal.selected.actuallySelectedOptionIds.forEach((id) => {
        url += `&option_ids[]=${id}`;
      });
    }

    if (params.get('utm_source')) {
      url += `&utm_source=${params.get('utm_source')}`;
    }

    if (params.get('utm_medium')) {
      url += `&utm_medium=${params.get('utm_medium')}`;
    }

    if (params.get('utm_campaign')) {
      url += `&utm_campaign=${params.get('utm_campaign')}`;
    }

    if (params.get('paint_preview')) {
      url += `&paint_preview=${params.get('paint_preview')}`;
    }

    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      accept: 'application/json',
      success: (result) => {
        dispatch({ type: 'STORE_UPDATED_PRICE', payload: result });

        window.history.replaceState({ turbolinks: {} }, '', url.replace('.json', ''));
        window.dispatchEvent(new PopStateEvent('popstate')); // Manually trigger popstate event
      },
      error: () => {}, // TODO: do we need to actually use this?
    });
  };

  const setIsPageLoad = () => {
    dispatch({ type: 'SET_TURBOLINKS_LOAD' });
  };

  useEffect(() => {
    fetchPrice();

    window.addEventListener('turbolinks:load', setIsPageLoad);
    return () => window.removeEventListener('turbolinks:load', setIsPageLoad);
  }, [initialised, contractLength, annualMileage, initialMonths, isFinanceLease, deal.selected.optionIds]);

  // Initialise Options once we have a derivateID present
  useEffect(() => {
    initialiseOptions();
  }, [deal.derivativeId]);

  // this bit is really important. We need to wait until redux has been initialised with the right data before continuing.
  if (!initialised) return null;

  return (
    <div className="drv-deal-sidebar-selections l-deal__sidebar-selections">
      <h2 className="drv-deal-sidebar-title d-none d-lg-block">Personalise your lease</h2>
      {isVan && (
        <div className="drv-switch drv-mt-6 drv-mb-4">
          <input
            className="drv-switch-checkbox"
            type="checkbox"
            checked={isFinanceLease}
            onChange={(e) =>
              dispatch({
                type: 'STORE_FINANCE_LEASE',
                payload: e.target.checked,
              })
            }
          />
          <label className="drv-switch-label">
            <span className="drv-switch-label-option">Contract Hire</span>
            <span className="drv-switch-label-option">Finance Lease</span>
          </label>
        </div>
      )}
      {isFinanceLease ? (
        <FinanceLease />
      ) : (
        <>
          <MotoringTerms
            price={price}
            annualMileage={annualMileage}
            contractLength={contractLength}
            initialMonths={initialMonths}
            mileages={mileages}
            shortTermMileages={shortTermMileages}
            terms={terms}
            shortTerms={shortTerms}
            initialPayments={initialPayments}
            initialPayment={initialPayment}
            shortTermInitialPaymentsByTerm={shortTermInitialPaymentsByTerm}
            defaultInitialPaymentMonthsByTerm={defaultInitialPaymentMonthsByTerm}
            hasShortTerm={hasShortTerm}
            hasStandardTerm={hasStandardTerm}
            isPersonal={init.isPersonal}
          />

          {!Number.isNaN(maintenancePrice) && maintenancePrice > 0 && (
            <>
              <div className="drv-text-larger">Add a Maintenance and Tyre Plan</div>
              <div className="drv-block drv-mb-4">
                Only{' '}
                <span className="drv-deal-sidebar__option-value drv-text-gold-darker drv-text-bold">
                  £{maintenancePrice.toFixed(2)}
                </span>{' '}
                {init.isPersonal ? 'inc. VAT' : 'ex. VAT'}{' '}
                <a
                  href="/guides/personal-car-leasing/servicing-and-maintenance-packages"
                  target="_blank"
                  className="drv-link drv-text-accent drv-text-bold"
                >
                  Find out more
                </a>
              </div>
              <label htmlFor="maintenanceCheck-true" className="drv-checkbox__label drv-ml-1">
                <input
                  type="radio"
                  name="maintenanceCheck"
                  id="maintenanceCheck-true"
                  className="drv-checkbox drv-checkbox--radio"
                  value="true"
                  checked={maintenanceSelected}
                  onChange={() =>
                    dispatch({
                      type: 'STORE_MAINTENANCE_SELECTED',
                      payload: true,
                    })
                  }
                />
                <span className="">Yes I’d like the maintenance plan</span>
              </label>
              <label htmlFor="maintenanceCheck-false" className="drv-checkbox__label drv-ml-1">
                <input
                  type="radio"
                  name="maintenanceCheck"
                  id="maintenanceCheck-false"
                  className="drv-checkbox drv-checkbox--radio"
                  value="false"
                  checked={!maintenanceSelected}
                  onChange={() =>
                    dispatch({
                      type: 'STORE_MAINTENANCE_SELECTED',
                      payload: false,
                    })
                  }
                />
                <span className="">No, I&apos;ll maintain it myself</span>
              </label>
            </>
          )}

          {!isUsed && (
            <div className="drv-block drv-mt-8">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="drv-button drv-button--blue drv-button--large drv-text-bold"
                href="#"
                data-action="modal#load"
                data-target="options-modal"
                data-path={`${currentChannelPath(paths.options)}?deal_id=${deal.dealId}`}
                role="button"
              >
                Select colour, trim and options
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Pricing.propTypes = {
  init: PropTypes.shape({
    derivativeId: PropTypes.number.isRequired,
    dealId: PropTypes.number,
    modelYear: PropTypes.string,
    monthlyPrice: PropTypes.number,
    financeLeaseInitialPaymentPrice: PropTypes.number,
    financeLeaseMonthlyPrice: PropTypes.number,
    maintainedInitialPaymentPrice: PropTypes.number,
    maintainedMonthlyPrice: PropTypes.number,
    initialPaymentPrice: PropTypes.number,
    contractLength: PropTypes.number.isRequired,
    annualMileage: PropTypes.number.isRequired,
    initialMonths: PropTypes.number,
    useFinanceLease: PropTypes.bool,
    isPersonal: PropTypes.bool.isRequired,
    isShortTerm: PropTypes.bool.isRequired,
    make: PropTypes.string,
    model: PropTypes.string,
    trim: PropTypes.string,
    fuelType: PropTypes.string,
    transmission: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
  isVan: PropTypes.bool.isRequired,
  isUsed: PropTypes.bool.isRequired,
  terms: PropTypes.arrayOf(PropTypes.number).isRequired,
  mileages: PropTypes.arrayOf(PropTypes.number).isRequired,
  paths: PropTypes.shape({
    enquire: PropTypes.string.isRequired,
    options: PropTypes.string.isRequired,
  }).isRequired,
  hasShortTerm: PropTypes.bool,
  hasStandardTerm: PropTypes.bool,
  shortTermMileages: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortTerms: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialPayments: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortTermInitialPaymentsByTerm: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  defaultInitialPaymentMonthsByTerm: PropTypes.objectOf(PropTypes.number).isRequired,
  channel: PropTypes.string,
};

Pricing.defaultProps = {
  hasShortTerm: false,
  hasStandardTerm: false,
  channel: '',
};

export default function PricingWrapper(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Pricing {...props} />
    </Provider>
  );
}
