import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, Provider } from 'react-redux';
import Rails from '@rails/ujs';

import NumberFormat from 'react-number-format';
import store from '../../store/store';
import SalesInfoItem from './SalesInfoItem';
import Dealer from './Dealer';

// Helpers
import { getSelectedOptions, isPaintwork, isTrim, isOther, selectedOfferId } from './helpers';

const SalesInfo = ({
  dealJson,
  emailQuotePath,
  reportAProblemPath,
  financeLeaseTerm,
  financeLeaseMileage,
  financeLeaseInitialPayment,
}) => {
  const data = useSelector((state) => state.Deal);
  const [state, setState] = useState({
    deal: {},
    derivative: {},
    dealers: [],
  });

  const [otherOptions, setOtherOptions] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [trimOptions, setTrimOptions] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  const deal = JSON.parse(dealJson);

  const fetchOffer = () => {
    const offerId = selectedOfferId(data);

    if (offerId) {
      Rails.ajax({
        url: `/offers/${offerId}.json`,
        type: 'GET',
        dataType: 'json',
        accept: 'application/json',
        success: (result) => {
          setState(result);
        },
        error: () => {},
      });
    } else {
      setState({
        deal: {},
        derivative: {},
        dealers: [],
      });
    }
  };

  const getOptions = () => {
    if (data.selected.optionIds.length > 0) {
      const selectedColour = getSelectedOptions(data).filter(isPaintwork);
      const selectedTrim = getSelectedOptions(data).filter(isTrim);
      const selectedOther = getSelectedOptions(data).filter(isOther);

      setColourOptions(selectedColour);
      setTrimOptions(selectedTrim);
      setOtherOptions(selectedOther);
    }
  };

  useEffect(() => {
    fetchOffer();
    getOptions();
  }, [data.offerId, data.selected.optionIds, data.maintenanceSelected]);

  const MISSING_VALUE = '-';

  const formatLeadTimes = () => {
    const formattedArray = [];

    state.leadTimes.forEach((item) => {
      // eslint-disable-next-line no-useless-escape
      formattedArray.push(item.leadTime.replace(/<br\s*[\/]?>/gi, '\n'));
    });

    return formattedArray.join(' / ');
  };

  const formatDealType = (value) => {
    const splitValue = value.replace(/([A-Z])/g, ' $1');
    const result = splitValue.charAt(0).toUpperCase() + splitValue.slice(1);

    return result;
  };

  const listOptions = (options) => {
    return options.map((option) => option.description);
  };

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className={`g-deal-sales-info__wrap ${isHidden ? 'hidden' : ''}`}>
      <button className="g-deal-sales-info__toggle-button" onClick={toggleVisibility}>
        {isHidden ? 'Show sales info' : 'Hide sales info'}
      </button>
      <div className="g-deal-sales-info__scroll-container">
        <div className="g-deal-sales-info__group">
          <div className="g-deal-sales-info__item">
            <h4>Funder</h4>

            {state.funderName ? (
              <p>
                <span className={`b-label b-label--${state.funderName.toLowerCase()}`}>{state.funderName}</span>
                <span className="text-uppercase font-weight-bold">
                  {data.maintenanceSelected && state.deal.withMaintenance && ' & Rivervale'}
                </span>
              </p>
            ) : (
              <p>{MISSING_VALUE}</p>
            )}
          </div>

          <div className="g-deal-sales-info__item">
            <h4>Marketing support</h4>
            <p>{state.marketingSupport || MISSING_VALUE}</p>
          </div>

          {state.leadTimes && <SalesInfoItem title="Lead times" content={formatLeadTimes()} />}

          <div className="g-deal-sales-info__item">
            <h4>Deal expires</h4>
            <p>{state.deal.expiresAt || 'No expiry'}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>Deal type</h4>
            <p>{state.deal.type ? formatDealType(state.deal.type) : MISSING_VALUE}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>CAP ID</h4>
            <p>{state.derivative.capId || MISSING_VALUE}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>Managed by</h4>
            <p>{state.deal.managedBy || MISSING_VALUE}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>Price updated</h4>
            <p>
              {state.deal.lastUpdatedAt ? (
                <>
                  {state.deal.lastUpdatedAt} ago
                  {state.deal.lastUpdatedBy ? ` by ${state.deal.lastUpdatedBy}` : ''}
                </>
              ) : (
                MISSING_VALUE
              )}
            </p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>In stock?</h4>
            <p>{state.deal.stock || MISSING_VALUE}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>Is pre-order?</h4>
            <p>{state.deal.pre_order || MISSING_VALUE}</p>
          </div>

          <div className="g-deal-sales-info__item">
            <h4>
              Model year
              {state.deal.specAt ? ' / spec date' : ''}
            </h4>
            <p>
              {state.derivative.modelYear || MISSING_VALUE}
              {state.deal.specAt ? ` / ${state.deal.specAt}` : ''}
            </p>
          </div>

          {!state.deal.short_term && (
            <>
              <div className="g-deal-sales-info__item">
                <h4>Funder commission</h4>
                <p>{state.commission || MISSING_VALUE}</p>
              </div>

              <div className="g-deal-sales-info__item">
                <h4>Paint</h4>
                <p>{state.paint || MISSING_VALUE}</p>
              </div>

              <SalesInfoItem title="OTR 1" content={state.otr} />

              <SalesInfoItem title="OTR 2" content={state.otr2} />

              <div className="g-deal-sales-info__item">
                <h4>Discount</h4>
                <p>{state.discount || MISSING_VALUE}</p>
              </div>
            </>
          )}

          {state.deal.short_term && (
            <>
              {state.doc_fee && (
                <div className="g-deal-sales-info__item">
                  <h4>Doc fee</h4>
                  <p>
                    <NumberFormat
                      value={state.doc_fee}
                      displayType="text"
                      thousandSeparator
                      prefix="£"
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </p>
                </div>
              )}
              {state.collection_fee && (
                <div className="g-deal-sales-info__item">
                  <h4>Collection fee</h4>
                  <p>
                    <NumberFormat
                      value={state.collection_fee}
                      displayType="text"
                      thousandSeparator
                      prefix="£"
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </p>
                </div>
              )}
              <SalesInfoItem title="Includes insrance?" content={state.includes_insurance} />
              <SalesInfoItem title="Includes maintenance?" content={state.includes_maintenance} />
              <SalesInfoItem title="includes breakdown cover?" content={state.includes_breakdown_cover} />
              <SalesInfoItem title="Minimum age" content={state.minimum_age} />
              <SalesInfoItem title="Discount code" content={state.discount_code} />
              <SalesInfoItem title="Discount amount" content={state.discount_amount} />
              {state.funder_url && (
                <div className="g-deal-sales-info__item">
                  <p>
                    <a href={state.funder_url} target="_blank" rel="noreferrer">
                      View Funder Website
                    </a>
                  </p>
                </div>
              )}
            </>
          )}

          <SalesInfoItem alert title="Restrictions / additional information" content={state.restrictions} />
        </div>

        {state.dealers.map((dealer) => (
          <Dealer
            key={dealer.id}
            commission={dealer.commission}
            contact={dealer.contact}
            leadTime={dealer.leadTime}
            link={dealer.link}
            name={dealer.name}
            stockList={dealer.stock_list}
          />
        ))}
      </div>
      <div className="g-deal-sales-info__footer">
        <div className="g-deal-sales-info__item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="b-btn btn-block b-btn-link b-btn-link--danger"
            data-action="modal#load"
            data-target="report_a_problem_modal"
            data-path={`${reportAProblemPath}?deal_type=${state.deal.type && formatDealType(state.deal.type)}&funder_maintenance=${data.maintenanceSelected}&colour_options=${listOptions(colourOptions)}&trim_options=${listOptions(trimOptions)}&other_options=${listOptions(otherOptions)}`}
            role="button"
          >
            Report this deal
          </a>
        </div>

        {/* NOTE: Current implementation allow to send the form before fetching the offer details due to async logic if you click super fast */}
        <div className="g-deal-sales-info__item">
          <form data-action="modal#load" data-target="quote_modal" action={emailQuotePath} method="post">
            <input type="hidden" name="offer[id]" value={data.offerId} />
            <input
              type="hidden"
              name="quotation[type]"
              value={data.isUsed ? 'UsedVehicleQuotation' : 'NewVehicleQuotation'}
            />
            <input type="hidden" name="quotation[is_electric]" value={data.pluginOrElectric || ''} />
            <input type="hidden" name="quotation[deal_id]" value={deal.id || ''} />
            <input type="hidden" name="quotation[derivative_id]" value={deal.derivative_id || ''} />
            <input type="hidden" name="quotation[monthly_rental]" value={data.monthlyPrice || ''} />
            <input
              type="hidden"
              name="quotation[maintained_monthly_rental]"
              value={data.maintainedMonthlyPrice || ''}
            />
            <input
              type="hidden"
              name="quotation[term]"
              value={data.isFinanceLease ? financeLeaseTerm : data.contractLength || ''}
            />
            <input
              type="hidden"
              name="quotation[mileage]"
              value={data.isFinanceLease ? financeLeaseMileage : data.annualMileage || ''}
            />
            <input
              type="hidden"
              name="quotation[initial_payments]"
              value={data.isFinanceLease ? financeLeaseInitialPayment : data.initialMonths || ''}
            />
            <input type="hidden" name="quotation[funder_maintenance]" value={data.maintenanceSelected || false} />
            <input
              type="hidden"
              name="quotation[maintenance]"
              value={data.maintainedMonthlyPrice - data.monthlyPrice || ''}
            />
            {colourOptions.length > 0 &&
              colourOptions.map((option) => (
                <input
                  type="hidden"
                  name="quotation[colour_option]"
                  value={option.description}
                  key={option.description}
                />
              ))}
            {trimOptions.length > 0 &&
              trimOptions.map((option) => (
                <input
                  type="hidden"
                  name="quotation[trim_option]"
                  value={option.description}
                  key={option.description}
                />
              ))}
            {otherOptions.length > 0 &&
              otherOptions.map((option) => (
                <input
                  type="hidden"
                  name="quotation[other_options][]"
                  value={option.description}
                  key={option.description}
                />
              ))}
            <p>
              <button className="b-btn b-btn-green b-btn-block" type="submit">
                Send email quote
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

SalesInfo.propTypes = {
  emailQuotePath: PropTypes.string.isRequired,
  financeLeaseTerm: PropTypes.number.isRequired,
  financeLeaseMileage: PropTypes.number.isRequired,
  financeLeaseInitialPayment: PropTypes.number.isRequired,
  reportAProblemPath: PropTypes.string.isRequired,
  dealJson: PropTypes.string.isRequired,
};

export default function SalesInfoWrapper(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SalesInfo {...props} />
    </Provider>
  );
}
