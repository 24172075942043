import React from 'react';
import PropTypes from 'prop-types';

import { Provider, useSelector } from 'react-redux';

import store from '../../store/store';

import MonthlyPrice from './MonthlyPrice/MonthlyPrice';
import UpfrontPrice from './UpfrontPrice/UpfrontPrice';

const Enquire = ({ paths, leaseType, isPersonal, isVan, phoneNumber, dealType }) => {
  const deal = useSelector((state) => state.Deal);

  const {
    monthlyPrice,
    maintainedMonthlyPrice,
    maintenanceSelected,
    initialPaymentPrice,
    maintainedInitialPaymentPrice,
    isFinanceLease,
  } = deal;

  const enquirePath = `${paths.enquire}&use_finance_lease=${isFinanceLease}`;
  const callbackPath = paths.callback;

  const price = maintenanceSelected ? maintainedMonthlyPrice : monthlyPrice;
  const initialPayment = maintenanceSelected ? maintainedInitialPaymentPrice : initialPaymentPrice;

  const carDealTypeLabel = dealType === 'RatebookDeal' ? 'Guide price' : leaseType;

  return (
    <>
      <div className="g-deal-enquire__price">
        <div className="g-deal-enquire__monthly">
          <h4>
            {dealType === 'RatebookDeal' && (
              <span
                className="b-info"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="This is an auto generated rate. Please enquire for most competitive quote."
              />
            )}
            {isVan ? 'Monthly Payment' : carDealTypeLabel} {!isPersonal && <span>ex VAT</span>}
          </h4>
          <MonthlyPrice price={price} isLoading={deal.URLParamsInit && !deal.URLParamsApplied} isEnquire />
        </div>
        {typeof initialPayment === 'number' && initialPayment > 0 && (
          <UpfrontPrice
            price={initialPayment}
            isPersonal={isPersonal}
            isLoading={deal.URLParamsInit && !deal.URLParamsApplied}
            isEnquire
          />
        )}
      </div>
      <div className="drv-deal-enquire__action">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          data-action="modal#load"
          data-target="enquire-modal"
          data-path={enquirePath}
          role="button"
          className="drv-deal-enquire__button drv-button drv-button--green drv-button--xl drv-button--icon"
        >
          ENQUIRE
          <span className="drv-button__icon" />
        </a>
      </div>
      <div className="g-deal-enquire__additional">
        <p className="g-deal-enquire__call">
          Call <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </p>
        <p className="g-deal-enquire__callback">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" data-action="modal#load" data-target="callback-modal" data-path={callbackPath} role="button">
            Request a callback
          </a>
        </p>
      </div>
    </>
  );
};

Enquire.propTypes = {
  paths: PropTypes.shape({
    enquire: PropTypes.string.isRequired,
    callback: PropTypes.string.isRequired,
  }).isRequired,
  leaseType: PropTypes.string.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  isVan: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  dealType: PropTypes.string,
};

Enquire.defaultProps = {
  dealType: '',
};

export default function EnquireWrapper(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Enquire {...props} />
    </Provider>
  );
}
