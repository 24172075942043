import React from 'react';

const FinanceLease = () => (
  <div className="g-deal-options__financelease" style={{ display: 'block' }}>
    <div className="g-deal-options__info-block">
      <p>
        Finance Lease is available on variable contract term and mileage, please request a personalised quote below.
      </p>
    </div>
    <ul className="c-list-ticks-featured">
      <li className="c-list-ticks-featured__item">
        Finance Lease offers more end-of-contract options than with traditional Contract Hire.
      </li>
      <li className="c-list-ticks-featured__item">
        Opportunity to sell the vehicle at the end of the initial agreement and to enjoy a share of the proceeds.
      </li>
      <li className="c-list-ticks-featured__item">
        Flexibility to retain the use of your vehicle at the end of the contract by entering a secondary rental period.
      </li>
      <li className="c-list-ticks-featured__item">
        Lower your monthly fees with a &apos;Balloon Payment&apos; Finance Lease arrangement.
      </li>
      <li className="c-list-ticks-featured__item">
        Business lease customers can take advantage of tax benefits in terms of claimable expenses and recoverable VAT.
      </li>
      <li className="c-list-ticks-featured__item">Contact a Select Van Leasing consultant for a personalised quote.</li>
    </ul>
  </div>
);

export default FinanceLease;
