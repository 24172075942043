import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Style Imports
import './UpfrontPrice.scss';

const UpfrontPrice = ({ price, isPersonal, isLoading, isEnquire }) => (
  <div className={isEnquire ? 'g-deal-enquire__upfront' : 'g-deal-options__upfront'}>
    <h3>
      Initial payment
      {isPersonal ? ' inc' : ' ex'} VAT
    </h3>

    {isLoading ? (
      <span className="upfront-price__loading" />
    ) : (
      <p className={`g-deal-enquire__price-info ${!isPersonal ? 'business' : null}`}>
        {isEnquire ? (
          <>
            <NumberFormat
              value={price}
              displayType="text"
              thousandSeparator
              prefix="£"
              fixedDecimalScale
              decimalScale={2}
            />
            <span className="g-deal-enquire__desktop"> (Plus admin fee)</span>
            <span className="g-deal-enquire__mobile"> Initial Payment </span>
          </>
        ) : (
          <>
            <NumberFormat
              value={price}
              displayType="text"
              thousandSeparator
              prefix="£"
              fixedDecimalScale
              decimalScale={2}
            />
            <span className="g-deal-options__admin"> (Plus admin fee)</span>
          </>
        )}
      </p>
    )}
  </div>
);

UpfrontPrice.propTypes = {
  price: PropTypes.number.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isEnquire: PropTypes.bool,
};

UpfrontPrice.defaultProps = {
  isLoading: false,
  isEnquire: false,
};

export default UpfrontPrice;
