import React from 'react';
import PropTypes from 'prop-types';

const SummaryItem = ({ name, selected }) => {
  return (
    <li className="c-list-table__item">
      <span className="c-list-table__title">{name}:</span>

      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      <span className="c-list-table__data" rel="options">
        {selected.length > 0 ? (
          selected.map((item) => <span key={item.description}>{item.description}</span>)
        ) : (
          <span>-</span>
        )}
      </span>
    </li>
  );
};

SummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default SummaryItem;
