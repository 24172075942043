import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import OptionSelectorControl from './OptionSelectorControl';
import OptionSelectorPagination from './OptionSelectorPagination';
import OptionSelectorSummary from './OptionSelectorSummary';
import OptionSelectorStockWarning from './OptionSelectorStockWarning';
import OptionsGroup from '../OptionsGroup';

const OptionSelectorPage = ({
  categories,
  name,
  pages,
  isPersonal,
  enquirePath,
  isLast,
  channel,
  jumpToStep,
  step,
  loggedIn,
}) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.Deal);

  const [isTwoColumns, setIsTwoColumns] = useState(false);

  const nextClicked = () => {
    jumpToStep(step + 1);

    dispatch({
      type: 'SET_DEAL_CURRENT_STEP',
      payload: !isLast ? query.currentStep + 1 : query.currentStep,
    });
  };

  const backClicked = () => {
    jumpToStep(step - 1);

    dispatch({
      type: 'SET_DEAL_CURRENT_STEP',
      payload: query.currentStep - 1,
    });
  };

  return (
    <>
      <OptionSelectorPagination name={name} pages={pages} jumpToStep={jumpToStep} loggedIn={loggedIn} />

      <div className="option-selector__page">
        <div className="option-selector__page-inner option-selector__options">
          <OptionSelectorStockWarning enquirePath={enquirePath} channel={channel} />

          <div
            className={classNames('option-selector__page-content', {
              'option-selector__page-content--two-columns': isTwoColumns,
            })}
          >
            <OptionsGroup
              categories={categories}
              onNext={nextClicked}
              onBack={backClicked}
              channel={channel}
              setTwoColumns={setIsTwoColumns}
            />

            {!query.isStock && !query.isFrozen && (
              <p className="options-selector-group-skip">
                Choose an option
                {!(loggedIn && query.salesView) && (
                  <>
                    {' '}
                    or{' '}
                    <button type="button" className="b-btn b-btn-link b-btn-sm" onClick={nextClicked}>
                      Skip this step
                    </button>
                  </>
                )}
              </p>
            )}
          </div>
        </div>

        <div className="option-selector__page-inner option-selector__summary-wrapper">
          <OptionSelectorSummary isPersonal={isPersonal} jumpToStep={jumpToStep} />
        </div>
      </div>

      <OptionSelectorControl
        isLast={isLast}
        pages={pages}
        step={step}
        nextClicked={nextClicked}
        enquirePath={enquirePath}
      />
    </>
  );
};

OptionSelectorPage.propTypes = {
  name: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  step: PropTypes.number.isRequired,
  enquirePath: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.number).isRequired,
  jumpToStep: PropTypes.func,
  isPersonal: PropTypes.bool.isRequired,
};

OptionSelectorPage.defaultProps = {
  jumpToStep: () => {},
};

export default OptionSelectorPage;
