/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, Provider, useDispatch } from 'react-redux';

// Redux store
import store from '../../store/store';

// Helpers
import { contains } from '../../utils';
import { numberWithCommas } from './helpers';

const PaintSwatch = memo(({ description, lowLight, midLight, highLight, isSelected }) => {
  const style = {
    background: `linear-gradient(90deg, ${lowLight} 0%, ${midLight} 50%, ${highLight} 100%)`,
  };

  return (
    <span
      className={`drv-paint-swatch drv-paint-swatch--large ${isSelected ? 'drv-paint-swatch--selected' : ''}`}
      style={style}
      title={description}
    />
  );
});

PaintSwatch.propTypes = {
  description: PropTypes.string.isRequired,
  lowLight: PropTypes.string.isRequired,
  midLight: PropTypes.string.isRequired,
  highLight: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const PaintSwatches = ({ paintsData, initialPaint }) => {
  const deal = useSelector((state) => state.Deal);
  const dispatch = useDispatch();
  const [selectedPaint, setSelectedPaint] = useState(null);
  const [showPaints, setShowPaints] = useState(false);
  const isFrozenOrStock = deal.isFrozen || deal.isStock;

  useEffect(() => {
    let newSelectedPaint = null;
    const url = new URL(window.location);
    const paintPreviewId = url.searchParams.get('paint_preview');

    if (paintPreviewId) {
      if (isFrozenOrStock) {
        newSelectedPaint = deal.options.find((option) => String(option.id) === String(paintPreviewId));
        setSelectedPaint(newSelectedPaint);
        return;
      }
      url.searchParams.delete('paint_preview');
      window.history.replaceState({}, '', url);
    }

    if (deal.URLParamsInit || deal.URLParamsApplied) {
      const selectedPaintId = paintsData.find(({ cap_paint }) =>
        contains(String(cap_paint.option_code), deal.selected.actuallySelectedOptionIds.map(String)),
      );
      if (selectedPaintId) {
        newSelectedPaint = deal.options.find(
          (option) => String(option.id) === String(selectedPaintId.cap_paint.option_code),
        );
        setSelectedPaint(newSelectedPaint);
      }
    } else if (initialPaint) {
      const initialPaintOption = paintsData.find(({ imagin_paint }) => imagin_paint?.code === initialPaint);
      if (initialPaintOption) {
        newSelectedPaint = deal.options.find(
          (option) => String(option.id) === String(initialPaintOption.cap_paint.option_code),
        );
        setSelectedPaint(newSelectedPaint);
      }
    }
  }, [
    deal.URLParamsInit,
    deal.URLParamsApplied,
    deal.selected.actuallySelectedOptionIds,
    deal.options,
    initialPaint,
    paintsData,
    isFrozenOrStock,
  ]);

  const handlePaintSelect = (optionId) => {
    if (selectedPaint) {
      if (!isFrozenOrStock) {
        dispatch({
          type: 'OPTIONS.REMOVE',
          id: String(selectedPaint.id),
        });
      } else {
        const url = new URL(window.location);
        url.searchParams.delete('paint_preview');
        window.history.replaceState({}, '', url);
      }
    }

    const newSelectedPaint = deal.options.find((option) => String(option.id) === String(optionId));
    setSelectedPaint(newSelectedPaint);

    if (!isFrozenOrStock) {
      dispatch({
        type: 'OPTIONS.ADD',
        id: String(optionId),
      });
    } else {
      const url = new URL(window.location);
      url.searchParams.set('paint_preview', optionId);
      window.history.replaceState({ turbolinks: {} }, '', url);
      window.dispatchEvent(new PopStateEvent('popstate'));
    }
  };

  const monthlyPrice = selectedPaint ? selectedPaint.price / (deal.initialMonths + deal.contractLength - 1) : 0;
  const displayPrice = monthlyPrice === 0 ? 'No cost' : `+ £${numberWithCommas(monthlyPrice.toFixed(2))} p/m`;

  return (
    <>
      <p className="drv-deal-hero__images-info drv-deal-hero__images-info--mobile">
        Images are for illustrative purposes only
      </p>
      <button
        className={`drv-paint-selector__toggle ${showPaints ? 'drv-paint-selector__toggle--open' : ''}`}
        onClick={() => setShowPaints(!showPaints)}
      >
        Select colour
      </button>

      <div className={`drv-paint-selector ${showPaints ? '' : 'drv-paint-selector--hidden'}`}>
        <div className="d-flex justify-content-between mb-4">
          {(deal.URLParamsApplied || isFrozenOrStock) && selectedPaint && (
            <div className="drv-paint-selector__description">
              <b>Select your Colour Swatch:</b>
              <br />
              <span className="drv-paint-selector__paint-name">
                {' '}
                {selectedPaint.description}{' '}
                {!isFrozenOrStock && <span className="drv-paint-selector__price">{displayPrice}</span>}
              </span>
            </div>
          )}
          <p className="drv-deal-hero__images-info drv-deal-hero__images-info--desktop">
            Images are for illustrative purposes only
          </p>
        </div>
        <div className="drv-paint-selector__wrapper">
          {paintsData.length > 0 &&
            paintsData.map(({ cap_paint, imagin_paint }) => {
              if (imagin_paint) {
                const isSelected = String(selectedPaint?.id) === String(cap_paint.option_code);
                return (
                  <div key={cap_paint.option_code} className="drv-paint-selector__radio-group">
                    <input
                      type="radio"
                      id={`paint_${cap_paint.option_code}`}
                      name="paint"
                      value={cap_paint.option_code}
                      checked={isSelected}
                      onChange={() => handlePaintSelect(cap_paint.option_code)}
                    />
                    <label htmlFor={`paint_${cap_paint.option_code}`} className="drv-paint-selector__label">
                      <PaintSwatch
                        description={cap_paint.description}
                        lowLight={imagin_paint.low_light}
                        midLight={imagin_paint.mid_light}
                        highLight={imagin_paint.high_light}
                        isSelected={isSelected}
                      />
                    </label>
                  </div>
                );
              }
              return null;
            })}
        </div>
        <p className="drv-paint-selector__disclaimer">
          Not all paint options may be available. Some colours may incur an additional fee. Please enquire
        </p>
      </div>
    </>
  );
};

PaintSwatches.propTypes = {
  initialPaint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  paintsData: PropTypes.arrayOf(
    PropTypes.shape({
      cap_paint: PropTypes.shape({
        option_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        description: PropTypes.string.isRequired,
      }).isRequired,
      imagin_paint: PropTypes.shape({
        code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        low_light: PropTypes.string.isRequired,
        mid_light: PropTypes.string.isRequired,
        high_light: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};

const PaintSwatchesWrapper = (props) => (
  <Provider store={store}>
    <PaintSwatches {...props} />
  </Provider>
);

export default PaintSwatchesWrapper;
