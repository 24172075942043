import React from 'react';
import PropTypes from 'prop-types';
import StepZilla from 'react-stepzilla';
import { Provider, useSelector } from 'react-redux';

// Component Imports
import OptionSelectorPage from './OptionSelectorPage';

// Categories
import {
  allCategories,
  categoriesColour,
  categoriesTrim,
  categoriesPacks,
  categoriesExterior,
  categoriesInterior,
  categoriesEquipment,
} from '../../../data/optionCategories';

// Redux
import store from '../../../store/store';

// Style Imports
import './OptionSelector.scss';

const OptionSelector = ({ isPersonal, enquirePath, channel, loggedIn }) => {
  const query = useSelector((state) => state.Deal);

  const stepsArray = [];

  const loggedInSteps = [{ name: 'All options', categories: allCategories }];

  const customerSteps = [
    { name: 'Colour', categories: categoriesColour },
    { name: 'Trim', categories: categoriesTrim },
    { name: 'Packs', categories: categoriesPacks },
    { name: 'Exterior', categories: categoriesExterior },
    { name: 'Interior', categories: categoriesInterior },
    { name: 'Equipment', categories: categoriesEquipment },
  ];

  const stepsView = loggedIn && query.salesView ? loggedInSteps : customerSteps;

  for (let i = 0; i < stepsView.length; i += 1) {
    const step = {
      name: stepsView[i].name,
      component: (
        <OptionSelectorPage
          step={i}
          name={stepsView[i].name}
          categories={stepsView[i].categories}
          isLast={i === stepsView.length - 1}
          loggedIn={loggedIn}
          pages={stepsView}
          isPersonal={isPersonal}
          enquirePath={enquirePath}
          channel={channel}
        />
      ),
    };

    stepsArray.push(step);
  }

  return (
    <StepZilla
      showSteps={false}
      steps={stepsArray}
      stepsNavigation={false}
      showNavigation={false}
      preventEnterSubmission
      startAtStep={query.currentStep - 1}
    />
  );
};

OptionSelector.propTypes = {
  isPersonal: PropTypes.bool.isRequired,
  enquirePath: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default function OptionSelectorProvider(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <OptionSelector {...props} />
    </Provider>
  );
}
