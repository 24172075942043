import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Style imports
import './OptionSelectorControl.scss';

const OptionSelectorControl = ({ isLast, step, pages, enquirePath, nextClicked }) => {
  const deal = useSelector((state) => state.Deal);

  const handleEnterPress = (event, fn) => {
    if (event.which === 13) {
      fn();
    }
  };

  const handleNext = () => {
    nextClicked();
  };

  const { monthlyPrice, maintainedMonthlyPrice, maintenanceSelected } = deal;

  const price = maintenanceSelected ? maintainedMonthlyPrice : monthlyPrice;

  const [wholePrice, decimalPrice] = price ? price.toFixed(2).split('.') : [];

  return (
    <div className="option-selector-control" role="group">
      {typeof price === 'number' && (
        <div className="option-selector-control__price-wrapper">
          <p className="option-selector-control__price-label">Per month</p>
          <p className="option-selector-control__price">
            £{wholePrice}.<span>{decimalPrice}</span>
          </p>
        </div>
      )}

      <div className="option-selector-control__buttons-wrapper">
        {!isLast && (
          <>
            <p className="option-selector-control__skip-label">
              Skip to
              <button
                className="option-selector-control__button-next"
                onKeyDown={(event) => handleEnterPress(event, handleNext)}
                onClick={handleNext}
              >
                next
              </button>
              or
            </p>
            <p className="option-selector-control__next-label">
              Next
              <button
                className="option-selector-control__button-next"
                onKeyDown={(event) => handleEnterPress(event, handleNext)}
                onClick={handleNext}
              >
                choose {pages[step + 1].name}
              </button>
              <span>or</span>
            </p>
          </>
        )}

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="option-selector-control__enquire b-btn-gold font-weight-bold drv-button--xl"
          href="#"
          data-action="modal#close modal#load"
          data-target="enquire-modal"
          data-path={enquirePath}
          role="button"
        >
          ENQUIRE
          <span className="drv-button__icon" />
        </a>
      </div>
    </div>
  );
};

OptionSelectorControl.propTypes = {
  isLast: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  enquirePath: PropTypes.string.isRequired,
  nextClicked: PropTypes.func.isRequired,
};

export default OptionSelectorControl;
